@font-face {
    font-family: 'icomoon';
    font-weight: normal;
    font-style: normal;

    src: url('../fonts/icomoon.eot?ro50g6');
    src: url('../fonts/icomoon.eot?#iefixro50g6') format('embedded-opentype'),
    url('../fonts/icomoon.woff?ro50g6') format('woff'),
    url('../fonts/icomoon.ttf?ro50g6') format('truetype'),
    url('../fonts/icomoon.svg?ro50g6#icomoon') format('svg');
}

/*****GLOBAL VARIABLES*****/
$font-stack-1: 'Merriweather', serif;
$font-stack-2: 'Source Sans Pro', sans-serif;

$pink: #FF2A66;
$blue: #13e4f1;
$green: #78ffd6;

/*****GLOBAL ELEMENTS*****/
* {
    position: relative;
}

body {
    font: 16px/1.8 $font-stack-1;
    margin: 0;
}

h1 {
    font: 900 42px/1.5em $font-stack-2;
}

h2 {
    font: 200 60px/1.5em $font-stack-2;
}

h3 {
    font: 900 18px/1.5em $font-stack-2;
    text-transform: uppercase;
}

p {
    margin: 0 0 20px;
}

a {
    text-decoration: none;
    color: $blue;
}

a:hover,
a:focus {
    text-decoration: none;
    color: $green;
    outline: none;
}

img {
    max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
    outline: thin dotted;
}

input[type=submit] {
    cursor: pointer;
}

hr {
    width: 18%;
    height: 5px;
    margin: 40px auto;
    border: none;
    background: $pink;
}

input[type=text],
input[type=email],
input[type=password],
textarea,
select {
    font: 13px/36px $font-stack-1;
    width: 100%;
    height: 36px;
    margin: 0 0 14px;
    padding: 0 8px;
    border: none;
    border-radius: 5px;
}

textarea {
    font: 13px/1.3 $font-stack-1;
    min-height: 90px;
    padding: 8px;
}

::selection {
    background: $blue; /* WebKit/Blink Browsers */
}
::-moz-selection {
    background: $blue; /* Gecko Browsers */
}

/*****GLOBAL CLASSES*****/

.clear {
    clear: both;
}

.button,
.long-button,
.single-comments-topics a {
    font: bold 14px/52px $font-stack-1;
    display: inline-block;
    height: 52px;
    padding: 0 22px;
    text-decoration: none;
    color: #fff;
    border: none;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    background: $pink;
}

.button:hover {
    color: $green;
    background: #000;
}

.button.large {
    font: bold 24px/64px $font-stack-1;
    height: 62px;
    padding: 0 30px;
}

.long-button {
    font: bold 14px/40px $font-stack-1;
    height: 40px;
    margin: 0 6px;
    border-radius: 20px;
}

.white-button {
    font: 400 20px/48px $font-stack-2;
    padding: 0 30px;
    color: $pink;
    border: 2px solid $pink;
    -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
            border-radius: 40px;
    background: #fff;
}

.white-button.large {
    font: 400 25px/58px $font-stack-2;
    padding: 0 34px;
}

.white-button:hover {
    color: $green;
    border-color: #000;
    background: #000;
}

.no-padding {
    padding: 0;
}

/*****HEADER*****/

.header {
    padding: 24px 0;
    border-top: 3px solid #000;
    border-bottom: 3px solid $pink;
    background: #fff;
}

.loggedin .header {
    padding: 32px 0 20px;
}

.header a {
    color: #000;
}

.header a.button {
    color: #fff;
}

.header a.button:hover,
.login > .button.logout:hover,
.login > .button.profile-link:hover {
    color: $green;
}

.login > .button.logout:hover,
.login > .button.profile-link:hover {
    background: #000;
}

.header .logo {
    font: 900 42px/1.5em $font-stack-2;
    display: block;
    float: left;
    margin: 5px 30px 0 0;
}

.loggedin .header .logo {
    margin: 10px 24px 0 0;
}

.header .logo span {
    display: inline-block;
    -webkit-transition: 600ms ease-in-out;
         -o-transition: 600ms ease-in-out;
            transition: 600ms ease-in-out;
}

.header .logo:hover span {
    color: $pink;
}

.header .logo:before {
    position: absolute;
    top: 0;
    left: 3px;
    width: 0;
    height: 0;
    content: '';
    -webkit-transition: 200ms linear;
         -o-transition: 200ms linear;
            transition: 200ms linear;
    border-width: 0 9px 15px 9px;
    border-style: solid;
    border-color: transparent transparent $pink transparent;
}

.header .logo:hover:before {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-animation: triangle 350ms linear;
            animation: triangle 350ms linear;
    border-color: transparent transparent #000 transparent;
}

@-webkit-keyframes triangle {
    0% {
        -webkit-transform: translateY(0) rotate(0deg);
    }
    30% {
        -webkit-transform: rotate(180deg) translateY(0);
    }
    60% {
        -webkit-transform: rotate(180deg) translateY(7px);
    }
    90% {
        -webkit-transform: rotate(180deg) translateY(-4px);
    }
    100% {
        -webkit-transform: rotate(180deg) translateY(0);
    }
}

@keyframes triangle {
    0% {
        transform: translateY(0) rotate(0deg);
    }
    30% {
        transform: rotate(180deg) translateY(0);
    }
    60% {
        transform: rotate(180deg) translateY(7px);
    }
    90% {
        transform: rotate(180deg) translateY(-4px);
    }
    100% {
        transform: rotate(180deg) translateY(0);
    }
}

.burger {
    display: none;
    width: 60px;
    height: 60px;
    margin: 0 0 15px 15px;
    -webkit-transition: 400ms linear;
         -o-transition: 400ms linear;
            transition: 400ms linear;
    vertical-align: top;
    border: 0;
    border-radius: 50%;
    background: #00d4e1;
}

.burger:hover,
.burger:focus {
    background: #000;
}

.active .burger {
    background: #000;
}

.burger span {
    position: absolute;
    top: 22px;
    right: 16px;
    left: 16px;
    height: 3px;
    margin: 0;
    -webkit-transition: 300ms ease-in-out;
         -o-transition: 300ms ease-in-out;
            transition: 300ms ease-in-out;
    -webkit-transform-origin: 0 1.5px;
       -moz-transform-origin: 0 1.5px;
        -ms-transform-origin: 0 1.5px;
         -o-transform-origin: 0 1.5px;
            transform-origin: 0 1.5px;
    border-radius: 20px;
    background: #fff;
}

.burger span:nth-child(2) {
    top: 29px;
}

.burger span:last-child {
    top: 36px;
}

.active .burger span {
    -webkit-transform: rotate(50deg) translateY(-6px);
       -moz-transform: rotate(50deg) translateY(-6px);
        -ms-transform: rotate(50deg) translateY(-6px);
         -o-transform: rotate(50deg) translateY(-6px);
            transform: rotate(50deg) translateY(-6px);
    background: $green;
}

.active .burger span:nth-child(2) {
    -webkit-transform: translateX(-10px);
        -ms-transform: translateX(-10px);
         -o-transform: translateX(-10px);
            transform: translateX(-10px);
    opacity: 0;
}

.active .burger span:last-child {
    -webkit-transform: rotate(-50deg) translateY(6px);
       -moz-transform: rotate(-50deg) translateY(6px);
        -ms-transform: rotate(-50deg) translateY(6px);
         -o-transform: rotate(-50deg) translateY(6px);
            transform: rotate(-50deg) translateY(6px);
}


.header h2 {
    font: 200 21px/1.3em $font-stack-2;
    margin: 0 0 10px;
}

.header .login h2 a {
    font-weight: 400;
    display: inline-block;
    margin: 0 0 0 4px;
    padding: 6px 8px;
    border-radius: 6px;
    background: $green;
}

.header .login h2 a:hover {
    color: $green;
    background: #000;
}

.header h2.logo a:hover {
    color: #000;
}

.header ul,
.footer ul {
    padding: 0;
}

.header ul li {
    display: inline-block;
}

.header ul.navigation {
    float: left;
    margin: 5px 0 0;
}

.loggedin .header ul.navigation {
    margin: 10px 0 0;
}

.header ul.navigation > li > a {
    font: 15px/72px $font-stack-1;
    display: block;
    height: 63px;
    padding: 0 18px;
}

.header ul.navigation > li:last-child > a {
    padding: 0 0 0 20px;
}

.header ul.navigation > li > a:hover {
    color: $pink;
}

.header ul.mobile-nav {
    display: none;
    clear: both;
    overflow: hidden;
    height: 0;
    margin: 0;
    -webkit-transition: 320ms linear;
         -o-transition: 320ms linear;
            transition: 320ms linear;
    text-align: left;
}

.header.active ul.mobile-nav {
    height: 288px;
}

.header ul.mobile-nav li {
    display: block;
}

.header ul.mobile-nav li a {
    font: bold 13px/48px $font-stack-1;
    display: block;
    height: 48px;
    padding: 0 40px;
    text-transform: uppercase;
    color: #fff;
    border-bottom: 1px solid #000;
    background: $pink;
}

.header ul.mobile-nav li:first-child a {
    border-top: 0;
}

.header.active ul.mobile-nav li:first-child a {
    border-top: 3px solid #000;
}

.header ul.mobile-nav li:last-child a {
    border-bottom: 0;
}

.header ul.mobile-nav li a:hover,
.header ul.mobile-nav li a:focus {
    background: #000;
}

.dropdown-menu {
    left: -24px;
    -webkit-animation-duration: .15s;
            animation-duration: .15s;
    border: none;
    border-radius: 5px;
    background: none;
    box-shadow: 0 0 0 2px #fff;
}

.dropdown-menu:after {
    position: absolute;
    top: -14px;
    left: 50%;
    width: 0;
    height: 0;
    margin: 0 0 0 -9px;
    content: '';
    border-width: 0 9px 14px 9px;
    border-style: solid;
    border-color: transparent transparent $pink transparent;
}

.header ul.dropdown-menu li {
    display: block;
    padding: 0;
}

.header ul.dropdown-menu li a {
    padding: 10px 0;
    text-align: center;
    color: #fff;
    background: $pink;
    text-shadow: none;
}

.header ul.dropdown-menu li:first-child a {
    border-radius: 5px 5px 0 0;
}

.header ul.dropdown-menu li:last-child a {
    border-radius: 0 0 5px 5px;
}

.header ul.dropdown-menu li a:hover {
    color: $green;
    background: #000;
}


/*****TOP MENU*****/
.top-menu {
    font-size: 0;
    margin: 40px 0;
    padding: 0;
    text-align: center;
}

.top-menu li {
    display: inline-block;
}

.top-menu li a {
    font: 24px/1.8 $font-stack-1;
    display: block;
    width: 120px;
    margin: 0 20px;
    -webkit-transition: linear 100ms;
         -o-transition: linear 100ms;
            transition: linear 100ms;
    text-transform: uppercase;
}

.top-menu li a:hover {
    -webkit-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
         -o-transform: translateY(-15px);
            transform: translateY(-15px);
    color: #fff;
}

.top-menu li a:before {
    display: block;
    width: 0;
    height: 0;
    margin: 0 0 10px;
    content: '';
    border-width: 0 60px 90px 60px;
    border-style: solid;
    border-color: transparent transparent $pink transparent;
}

.top-menu li a:hover:before {
    border-color: transparent transparent $green transparent;
}

.top-menu li a img {
    position: absolute;
    top: 42px;
    left: 50%;
    width: 32px;
    margin: 0 0 0 -16px;
}

/*****LOGIN SECTION*****/
.login  {
    margin-top: 12px;
    text-align: right;
}

.loggedin .login {
    margin: -14px 0 0;
}

.login > .button {
    margin: 0 0 0 12px;
}

.login > .button.profile-link {
    color: #000;
    background: $green;
}

.login > .button.logout {
    background: #00d4e1;
}

.header .login-popup {
    position: absolute;
    z-index: 25;
    top: 74px;
    right: 0;
    visibility: hidden;
    width: 265px;
    max-width: 100%;
    padding: 25px;
    -webkit-animation-duration: .6s;
            animation-duration: .6s;
    text-align: center;
    opacity: 0;
    border-radius: 5px;
    background: $blue;
}

.header .login-popup:before {
    position: absolute;
    top: -17px;
    right: 63px;
    width: 0;
    height: 0;
    content: '';
    border-width: 0 11px 18px 11px;
    border-style: solid;
    border-color: transparent transparent $blue transparent;
}

.login-popup.open {
    visibility: visible;
    opacity: 1;
}

.login-popup.open ~ .login-button  {
    color: $green;
    background: #000;
}

.login-popup input[type=submit] {
    font-size: 15px;
    display: block;
    width: 100%;
    height: 49px;
    text-transform: uppercase;
}

.login-popup h3 {
    overflow: hidden;
    margin: 14px 0;
    text-align: center;
    color: #000;
}
.login-popup h3:before,
.login-popup h3:after {
    position: relative;
    display: inline-block;
    width: 50%;
    height: 1px;
    content: '';
    vertical-align: middle;
    background-color: #000;
}
.login-popup h3:before {
    right: .5em;
    margin-left: -50%;
}
.login-popup h3:after {
    left: .5em;
    margin-right: -50%;
}

.login-popup-bg {
    position: absolute;
    z-index: 24;
    top: 0;
    bottom: 0;
    left: 0;
    display: none;
    width: 100%;
    background: none;
}

.button.facebook-link {
    display: block;
    white-space: nowrap;
    background: #3b5998;
}

.button.facebook-link:hover {
    background: #000;
}

.forgot-password {
    font: italic 12px/1.3 $font-stack-1;
    display: block;
    margin: 7px 0 0;
}

.forgot-password:hover {
    text-decoration: underline;
}

.login .validation-errors {
    font-size: 10px;
    position: absolute;
    right: 0;
    bottom: 2px;
    text-transform: uppercase;
    color: #000;
    text-shadow: none;
}

.login-popup .error {
    margin: 10px 0;
    padding: 10px;
    padding: 15px;
    color: $green;
    border-radius: 5px;
    background: #000;
}


/*****HOMEPAGE*****/
.home .main.black {
    text-align: center;
}

.home .main.black h2 {
    font: 200 60px/1.5em $font-stack-2;
    margin: 30px 0 10px;
}

.home-heading span {
    color: $pink;
}

h3.home-sub-heading {
    font: 900 22px/1.5em $font-stack-2;
    width: 80%;
    margin: 0 auto;
}

.top h3 {
    margin: 0 0 10px;
    color: $green;
}

.home .main {
    margin: 0 0 30px;
    padding: 0;
}

.home .main.black {
    margin: 0 0 15px;
}

.home .main ul {
    padding: 0;
    list-style: none;
}

.home .main h2 {
    margin: 50px 0 10px;
}

.home .main hr {
    margin: 0 auto 60px;
}

.home .main.black h2.home-heading {
    margin: 30px 0 20px;
}

.home .main hr.home-top-hr {
    margin: 30px auto 50px;
}

.main.black h2.message-heading {
    font: 24px/1.8 $font-stack-1;
    display: inline-block;
    margin: 0 0 50px;
    padding: 15px 45px;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    background: $pink;
}

.speech-bubble-container {
    margin-bottom: 10px;
    padding: 120px 0 0;
}

.speech-bubble-container .slick-slide {
    outline: 0;
}

.speech-bubble {
    display: inline-block;
    margin: 0 15px 30px;
    vertical-align: middle;
    opacity: 0;
    color: #fff;
}

.speech-bubble.context {
    max-width: 30%;
}

.speech-bubble.response {
    max-width: 50%;
}

.speech-bubble-container .animate .speech-bubble.context {
    -webkit-animation: fadeInBubbleLeft 400ms ease-in-out forwards; /* Chrome, Safari, Opera */
            animation: fadeInBubbleLeft 400ms ease-in-out forwards;
}

.speech-bubble-container .animate .speech-bubble.response {
    -webkit-animation: fadeInBubbleRight 400ms ease-in-out forwards; /* Chrome, Safari, Opera */
            animation: fadeInBubbleRight 400ms ease-in-out forwards;
}

.speech-bubble-container .animate .speech-bubble.response {
    -webkit-animation-delay: 1000ms; /* Chrome, Safari, Opera */
            animation-delay: 1000ms;
}

@-webkit-keyframes fadeInBubbleLeft {
    0% {
        -webkit-transform: translateX(-200px) scale(.3);
            -ms-transform: translateX(-200px) scale(.3);
             -o-transform: translateX(-200px) scale(.3);
                transform: translateX(-200px) scale(.3);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scale(1);
            -ms-transform: translateX(0) scale(1);
             -o-transform: translateX(0) scale(1);
                transform: translateX(0) scale(1);
        opacity: 1;
    }
}

/* Standard syntax */
@keyframes fadeInBubbleLeft {
    0% {
        -webkit-transform: translateX(-200px) scale(.3);
            -ms-transform: translateX(-200px) scale(.3);
             -o-transform: translateX(-200px) scale(.3);
                transform: translateX(-200px) scale(.3);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scale(1);
            -ms-transform: translateX(0) scale(1);
             -o-transform: translateX(0) scale(1);
                transform: translateX(0) scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes fadeInBubbleRight {
    0% {
        -webkit-transform: translateX(200px) scale(.3);
            -ms-transform: translateX(200px) scale(.3);
             -o-transform: translateX(200px) scale(.3);
                transform: translateX(200px) scale(.3);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scale(1);
            -ms-transform: translateX(0) scale(1);
             -o-transform: translateX(0) scale(1);
                transform: translateX(0) scale(1);
        opacity: 1;
    }
}

/* Standard syntax */
@keyframes fadeInBubbleRight {
    0% {
        -webkit-transform: translateX(200px) scale(.3);
            -ms-transform: translateX(200px) scale(.3);
             -o-transform: translateX(200px) scale(.3);
                transform: translateX(200px) scale(.3);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scale(1);
            -ms-transform: translateX(0) scale(1);
             -o-transform: translateX(0) scale(1);
                transform: translateX(0) scale(1);
        opacity: 1;
    }
}

.speech-bubble .speech-bubble-inner {
    font: 18px/1.8 $font-stack-1;
    padding: 20px 25px;
    color: #000;
    border-radius: 8px;
    background: $blue;
}

.speech-bubble.response .speech-bubble-inner {
    background: $green;
}

.speech-bubble p:last-child {
    margin: 0;
}

.speech-bubble h5 {
    font: 900 13px/1.5em $font-stack-2;
    margin: 4px 6px 0;
    text-transform: uppercase;
}

.home .main ul.slick-dots {
    position: absolute;
    top: 8px;
    left: 50%;
    width: 230px;
    margin: 0 0 0 -115px;
}

.slick-dots li {
    display: inline-block;
    margin: 0 5px 10px;
}

.slick-dots li.slick-active {
    margin: 0 -6px 10px;
}

.slick-dots li button {
    font-size: 0;
    width: 0;
    height: 0;
    padding: 0;
    -webkit-transition: 320ms linear;
         -o-transition: 320ms linear;
            transition: 320ms linear;
    border-width: 0 18px 30px 18px;
    border-style: solid;
    border-color: transparent transparent $pink transparent;
    background: none;
}

.slick-dots li.slick-active button {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
         -o-transform: rotate(180deg);
            transform: rotate(180deg);
    border-color: transparent transparent $green transparent;
}

.more-comments p {
    font: italic 12px/1.4 $font-stack-1;
    margin: 12px 0 0;
}

.more-comments a {
    display: inline-block;
    width: 62px;
    height: 62px;
    border: 4px solid $pink;
    border-radius: 50%;
}

.more-comments a:hover {
    border-color: $green;
}

.more-comments span {
    font: 32px/52px 'icomoon';
    display: block;
    padding: 0 0 0 1px;
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
         -o-transform: rotate(180deg);
            transform: rotate(180deg);
    animation: arrow-down 1.5s linear infinite;
    text-align: center;
    color: #fff;
}

.more-comments span:after {
    content: '\ea3a';
}

@keyframes arrow-down {
    25% {
        -webkit-transform: translateY(-4px) rotate(180deg);
            -ms-transform: translateY(-4px) rotate(180deg);
             -o-transform: translateY(-4px) rotate(180deg);
                transform: translateY(-4px) rotate(180deg);
    }
    75% {
        -webkit-transform: translateY(4px) rotate(180deg);
            -ms-transform: translateY(4px) rotate(180deg);
             -o-transform: translateY(4px) rotate(180deg);
                transform: translateY(4px) rotate(180deg);
    }
}

.col-sm-4.home-column {
    margin: 0;
    padding: 0 25px;
}

.home-full-width .col-sm-4.home-column {
    width: 100%;
}

.home .main h3 {
    text-align: center;
}

.home-top-area {
    padding: 30px 0 0;
}

.home-half-width {
    padding-right: 100px;
    padding-left: 100px;
}

.home-half-width:first-child {
    box-shadow: 1px 0 0 0 #f3f3f3;
}

.home-half-width h2 {
    font: 200 54px/1.2em $font-stack-2;
    margin: 20px 0 10px;
    padding: 0 0 8px;
    text-align: center;
}

.home-half-width hr {
    margin: 0 auto 75px;
}

.home-half-width .comment-meta {
    max-width: 40%;
}

.home-half-width h5.topics-heading {
    max-width: 60%;
}

.home-half-width .comment-meta span {
    display: none;
}

.home-half-width .comment-block:last-child {
    margin: 20px 0 30px;
}

.home-half-width .white-button {
    margin: 0 0 80px;
}

.main.black.trending-stories {
    padding: 90px 45px;
    border-top: 3px solid $pink;
}

.trending-stories .topic-heading {
    font: 900 34px/1.5em $font-stack-2;
    margin: 20px 0 30px;
    padding: 10px 40px;
}

.home .main.black.trending-stories h3 {
    margin: 90px 0 45px;
}

.home .main.black.trending-stories h3:first-child {
    margin: 0 0 30px;
}

.trending-stories h4 {
    font: 900 18px/1.6em "Source Sans Pro", sans-serif;
    margin: 0 0 20px;
}

.trending-stories .topic-image {
    display: inline-block;
    margin: 0;
}

.trending-stories .topic-image:hover img {
    border-color: #FF2A66;
}


.news-story-index .main ul {
    list-style: none;
    text-align: left;
}

.news-story-index .main ul li {
    margin: 0 0 20px;
}

.news-story-index .main ul li a {
    display: block;
    padding: 0 0 0 22px;
    color: $green;
}

.news-story-index .main ul li a:before {
    position: absolute;
    top: 1px;
    left: 0;
    content: '\25B6';
    color: $pink;
}

.news-story-index .main ul li a:hover {
    color: $pink;
}

.news-story-index .news-stories-subtitle {
    font-size: 15px;
    margin: 0 0 70px;
}

.news-story-index .news-stories-subtitle a {
    font-weight: bold;
    display: block;
    margin: 2px 0 0;
    color: $green;
}

.news-story-index .news-stories-subtitle a:hover {
    color: $pink;
}

.news-story-index .main hr {
    margin: 0 auto 25px;
}

.topics-index .main ul {
    padding: 0;
    list-style: 0;
}

.popular-topics-section ul li,
.topics-index .main ul li {
    display: inline-block;
    margin: 0 8px 16px;
}

.popular-topics-section ul li a,
.topics-index .main ul li a {
    padding: 0 40px;
    border-radius: 40px;
}

.trending-stories .white-button {
    margin: 60px 0 0;
    color: #fff;
    background: #000;
}

.trending-stories .white-button:hover {
    color: $green;
    border-color: #000;
    background: #000;
}

.home .main.best-comments-section {
    padding: 0 0 60px;
}

.home .main.popular-topics-section {
    padding: 60px 0 80px;
    background: #fcfcfc;
    box-shadow: 0 -1px 0 0 #f3f3f3,
    0 1px 0 0 #f3f3f3;
}

.home .main.popular-topics-section h2 {
    margin: 40px 0 10px;
}

.topics-index .main.black {
    color: #000;
    background: #fcfcfc;
}

.topics-index .main h1,
.news-story-index .main h1 {
    margin: 75px 0 20px;
}

.topics-index .main hr {
    margin: 0 auto 70px;
}

.popular-topics-section ul li a,
.topics-index .main ul li a {
    color: #fff;
    background: #13e4f1; /* Old browsers */
    background:    -moz-linear-gradient(top,  #13e4f1 0%, #05d5e2 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$blue), color-stop(100%,#05d5e2)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #13e4f1 0%,#05d5e2 100%); /* Chrome10+,Safari5.1+ */
    background:      -o-linear-gradient(top,  #13e4f1 0%,#05d5e2 100%); /* Opera 11.10+ */
    background:     -ms-linear-gradient(top,  #13e4f1 0%,#05d5e2 100%); /* IE10+ */
    background:         linear-gradient(to bottom,  #13e4f1 0%,#05d5e2 100%); /* W3C */

    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue', endColorstr='#05d5e2',GradientType=0 ); /* IE6-9 */
}

.popular-topics-section ul li a span,
.topics-index .main ul li a span {
    font: bold 10px/30px $font-stack-1;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 -20px 0 10px;
    vertical-align: middle;
    color: #fff;
    border-radius: 50%;
    background: #000;
}

.popular-topics-section ul li a:hover span,
.topics-index .main ul li a:hover span {
    background: $pink;
}

.popular-topics-section .white-button {
    margin: 25px 0 0;
}

.main.sign-up-section-bottom {
    padding: 40px 0 110px;
}

.home .main.sign-up-section-bottom h3 {
    font: 900 30px/1.5em $font-stack-2;
    margin: 75px 0 30px;
}

.home .main.sign-up-section-bottom p a {
    color: #000;
    border-bottom: 2px solid $blue;
}

.home .main.sign-up-section-bottom p a:hover {
    color: #9d9d9d;
    border-color: $green;
}

.inner-bottom-section:before {
    font-size: 13px;
    font-style: italic;
    position: absolute;
    top: 50%;
    left: 50%;
    content: 'OR';
    -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
         -o-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}

.sign-up-section-bottom .white-button {
    color: #fff;
    background: $pink;
}

.sign-up-section-bottom .white-button.facebook-button {
    border-color: #3b5998;
    background: #3b5998;
}

.sign-up-section-bottom .white-button:hover,
.sign-up-section-bottom .white-button.facebook-button:hover {
    color: $green;
    border-color: #000;
    background: #000;
}

.topic-heading {
    font: 900 22px/1.5em $font-stack-2;
    display: inline-block;
    margin: 0 0 50px;
    padding: 10px 30px;
    text-transform: uppercase;
    color: #000;
    border-radius: 5px;
    background: $green;
}

a.topic-heading:hover {
    color: #fff;
    background: $pink;
}

a.topic-heading:focus {
    color: #000;
}

.topic-heading:after {
    position: absolute;
    bottom: -20px;
    left: 50%;
    width: 0;
    height: 0;
    margin: 0 0 0 -20px;
    content: '';
    border-width: 30px 20px 0 20px;
    border-style: solid;
    border-color: $green transparent transparent transparent;
}

a.topic-heading:hover:after {
    border-color: $pink transparent transparent transparent;
}

.topic-heading.no-arrow:after {
    display: none;
}

.comment-block {
    margin: 20px 0 75px;
    border-radius: 5px;
}

.comment-block.nested {
    margin: 55px 4% 55px 20%;
}

.votes {
    position: absolute;
    top: -24px;
    left: -24px;
}

.votes button {
    font-weight: bold;
    line-height: 47px;
    z-index: 3;
    height: 50px;
    padding: 0;
    color: #000;
    border: 0;
    border: 2px solid $pink;
    border-radius: 30px;
    background: #fff;
}

.votes button.already-voted {
    border-color: $blue;
}

.votes button.already-voted span:first-child {
    background: $pink;
}

.comment-block .votes button:hover {
    color: $green;
    background: #000;
}

.votes button span {
    display: inline-block;
    margin: 0 20px 0 10px;
    vertical-align: top;
}

.votes button span:last-child:after {
    font: 22px/1.8 'icomoon';
    margin: 0 0 0 10px;
    content: '\ea3a';
    vertical-align: middle;
}

.votes button.already-voted span:last-child:after {
    content: '\e601';
}

.votes button span:first-child {
    font-size: 22px;
    line-height: 45px;
    width: 46px;
    height: 46px;
    margin: 0;
    color: #fff;
    border-radius: 50%;
    background: #000;
}

.comment-block .votes button:hover span:first-child {
    background: $pink;
}

.tooltip-content {
    font: 12px/1.5 $font-stack-1;
    position: absolute;
    z-index: 9999;
    bottom: 100%;
    left: 50%;
    width: 180px;
    margin: 0 0 16px -90px;
    padding: 10px 6px;
    cursor: default;
    -webkit-transition: opacity .3s, -webkit-transform .3s;
            transition: opacity .3s, transform .3s;
    -webkit-transform: translate3d(0,-10px,0);
            transform: translate3d(0,-10px,0);
    text-align: center;
    pointer-events: none;
    opacity: 0;
    color: #fff;
    border-radius: 5px;
    background: $pink;
}

.tooltip-content:after {
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 0;
    height: 0;
    margin: 0 0 0 -10px;
    content: '';
    border-width: 15px 10px 0 10px;
    border-style: solid;
    border-color: $pink transparent transparent transparent;
}

.votes:hover .tooltip-content,
.read-more:hover .tooltip-content,
.login-popup.open .tooltip-content {
    -webkit-transform: translate3d(0,0,0) rotate3d(0,0,0,0);
            transform: translate3d(0,0,0) rotate3d(0,0,0,0);
    pointer-events: auto;
    opacity: 1;
}

.active.tooltip-content {
    -webkit-animation: tooltipHover 350ms linear;
            animation: tooltipHover 350ms linear;
}

@-webkit-keyframes tooltipHover {
    0% {
        -webkit-transform: translate3d(0,-20px,0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate3d(0,0,0);
        opacity: 1;
    }
}

@keyframes tooltipHover {
    0% {
        transform: translate3d(0,-20px,0);
        opacity: 0;
    }
    100% {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
}

.active-right.tooltip-content {
    -webkit-animation: tooltipRight 175ms linear;
            animation: tooltipRight 175ms linear;
}

@-webkit-keyframes tooltipRight {
    0% {
        -webkit-transform: translate3d(10px,0,0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate3d(0,0,0);
        opacity: 1;
    }
}

@keyframes tooltipRight {
    0% {
        transform: translate3d(10px,0,0);
        opacity: 0;
    }
    100% {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
}

.active-left.tooltip-content {
    -webkit-animation: tooltipLeft 175ms linear;
            animation: tooltipLeft 175ms linear;
}

@-webkit-keyframes tooltipLeft {
    0% {
        -webkit-transform: translate3d(-10px,0,0);
        opacity: 0;
    }
    100% {
        -webkit-transform: translate3d(0,0,0);
        opacity: 1;
    }
}

@keyframes tooltipLeft {
    0% {
        transform: translate3d(-10px,0,0);
        opacity: 0;
    }
    100% {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
}

.tooltip-content.left {
    bottom: -30px;
    left: -135px;
    width: auto;
    margin: 0;
    padding: 10px 20px;
    -webkit-transform: translate3d(-10px,0,0);
            transform: translate3d(-10px,0,0);
    color: #fff;
}

.tooltip-content.left:after {
    right: -12px;
    bottom: 8px;
    left: auto;
    border-width: 10px 0 10px 15px;
    border-color: transparent transparent transparent $pink;
}

.login-popup .tooltip-content.left {
    top: 0;
    bottom: auto;
    left: -92%;
}

.tooltip-content.right {
    right: -135px;
    bottom: -30px;
    width: auto;
    margin: 0;
    padding: 10px 20px;
    -webkit-transform: translate3d(10px,0,0);
            transform: translate3d(10px,0,0);
    color: #fff;
}

.tooltip-content.right:after {
    right: auto;
    bottom: 8px;
    left: -12px;
    border-width: 10px 15px 10px 0;
    border-color: transparent $pink transparent transparent;
}

.login-popup .tooltip-content.right {
    bottom: 12px;
    width: 200px;
    padding: 10px;
}

.login-popup .tooltip-content.right:after {
    left: -1px;
}

.tooltip-content.active {
    width: 220px;
    margin: 0 0 16px -110px;
    background: $blue;
}

.tooltip-content.active:after {
    border-color: $blue transparent transparent transparent;
}

.read-more:hover .tooltip-content {
    color: #fff;
}

.comment-text-top {
    font: 700 18px/1.5em $font-stack-2;
    margin: 0;
    padding: 10px 20px 10px 160px;
    text-align: right;
    text-transform: none;
    border-top: 2px solid $blue;
    border-right: 2px solid $blue;
    border-left: 2px solid $blue;
    border-radius: 5px 5px 0 0;
    background: #fcfcfc;
    box-shadow: 0 1px 0 0 #f3f3f3;
}

.main h3.comment-text-top {
    text-align: right;
}

.comment-text {
    font: 20px/1.8 $font-stack-1;
    margin: 0;
    padding: 30px;
    border-right: 2px solid $blue;
    border-bottom: 2px solid $blue;
    border-left: 2px solid $blue;
    border-radius: 0 0 0 5px;
}

.comment-block .read-more {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    display: block;
    width: 0;
    height: 0;
    content: '';
    -webkit-transition: 120ms linear;
         -o-transition: 120ms linear;
            transition: 120ms linear;
    border-width: 0 0 35px 35px;
    border-style: solid;
    border-color: transparent transparent #000 transparent;
}

.comment-block .read-more:before {
    font: 14px/1.8 'icomoon';
    position: absolute;
    right: 3px;
    bottom: -37px;
    display: block;
    content: '\e9cb';
    color: #fff;
}

.comment-block .read-more:hover:before {
    color: $green;
}

.comment-text p:last-child,
.comment-text ul,
.comment-text ol {
    margin: 0;
}

.main .comment-text ul {
    margin: 10px 0;
    padding: 0 0 0 30px;
    list-style-type: disc;
}

h4.comment-meta {
    font: italic 13px/1.5 $font-stack-1;
    float: left;
    max-width: 60%;
    margin: 8px 0 0;
    padding: 0 0 0 32px;
    color: #cacaca;
}

h5.topics-heading {
    font: 14px/25px $font-stack-1;
    float: right;
    max-width: 40%;
    margin: 8px 0 0;
    padding: 0 0 5px;
    text-align: right;
}

h5.topics-heading a {
    display: inline-block;
    margin: 0 2px 6px;
    padding: 0 12px;
    color: #fff;
    border-radius: 25px;
    background: $pink;
}

h5.topics-heading a:hover {
    color: $green;
    background: #000;
}

.validation-errors {
    color: #f00;
}

.validation-errors.green {
    color: green;
}

.reply-form {
    overflow: hidden;
    height: 0;
    margin: 10px 0;
    padding: 0 20px;
    -webkit-transition: 500ms ease-in-out;
         -o-transition: 500ms ease-in-out;
            transition: 500ms ease-in-out;
    border-radius: 5px;
    background: #000;
}

.reply-form.show-form {
    height: auto;
    padding: 20px;
}

/*****INTERIOR*****/
.main {
    min-height: 300px;
    padding: 70px 0;
}

.main h1 {
    font: 200 60px/1.5em $font-stack-2;
    margin: 75px 0 35px;
    text-align: center;
}

.main h2,
.main h3 {
    text-align: center;
}

.main input[type=text],
.main input[type=email],
.main input[type=password],
.main textarea,
.main select {
    font: 16px/48px $font-stack-1;
    height: 48px;
    padding: 0 16px;
}

.main input[type=text]:hover,
.main input[type=email]:hover,
.main input[type=password]:hover,
.main textarea:hover,
.main select:hover {
    background: $green;
}

.main input[type=text]:focus,
.main input[type=email]:focus,
.main input[type=password]:focus,
.main textarea:focus,
.main select:focus {
    color: #fff;
    background: $pink;
}

.main textarea {
    font: 16px/24px $font-stack-1;
    min-height: 90px;
    padding: 16px;
}

.main label {
    font: 900 16px/1.3em $font-stack-2;
    display: block;
    margin: 0 0 8px;
    text-transform: uppercase;
    color: $blue;
}

.main.black {
    min-height: 400px;
    padding: 60px 0;
    color: #fff;
    border-bottom: 3px solid $blue;
    background: #000;
}

.main.black h2 {
    font: 200 42px/1.5em $font-stack-2;
}

.main.black h3 {
    color: $green;
}

.main.black h3.topic-heading {
    color: #000;
}

.main.black .button:hover {
    color: #000;
    background: $green;
}

.main.black .col-sm-offset-3 {
    margin-left: 25%;
}


.main.black .content {
    color: #fff;
}

/*****REGISTER PAGE*****/
.main .form-block {
    margin: 0 0 25px;
    padding: 0 30px;
}

.main.black .form-block {
    color: #000;
}

.main  .form-block.recaptcha-block {
    margin: 0 0 40px;
}

.main  .form-block .validation-errors,
.single-news .comment-block .validation-errors {
    font: 14px/1.8 $font-stack-1;
    padding: 14px 20px;
    text-align: center;
    color: #000;
    border-radius: 5px;
    background: $green;
}

.main  .form-block.successfully-submitted .validation-errors {
    font: 24px/1.8 $font-stack-1;
    margin: 30px 0 20px;
    color: #fff;
    background: $pink;
}

.main .form-block.successfully-submitted {
    width: 80%;
    margin: 0 auto 40px;
}

.recaptchatable #recaptcha_response_field {
    font: 10px/20px $font-stack-1;
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
    height: 20px;
}

.main .form-block label em {
    font: 900 italic 14px/20px $font-stack-2;
    color: $green;
}

.main .form-block .description {
    font: 12px/1.8 $font-stack-1;
    color: #fff;
}

.main .form-block .description span {
    color: $pink;
}

.main:not(.black) .form-block input,
.main:not(.black) .form-block textarea {
    border: 1px solid #000;
}

.main:not(.black) .form-block .description {
    color: #000;
}

/*****SUBMIT PAGE*****/

.main .form-block > .mce-panel {
    overflow: hidden;
    border: 1px solid #000;
    border-radius: 5px;
}

.main .mce-tinymce {
    margin: 0 0 14px;
}

/* TAG IT STYLING */
.main .ui-widget-content {
    border: 1px solid #000;
    border-radius: 5px;
}

.main ul.tagit li.tagit-choice {
    margin: 10px 5px;
    padding: 0 28px 0 16px;
    color: #fff;
    border: 0;
    background: $pink;
}

.main .tagit-label {
    font: 16px/42px $font-stack-1;
    display: inline-block;
}

.main .ui-icon {
    border-radius: 50%;
    background-color: #000;
}

.main ul.tagit li.tagit-choice .tagit-close {
    top: 21px;
    right: 7px;
}

.main ul.tagit input[type='text'] {
    margin: 0 5px;
    padding: 0 10px;
}

.main ul.tagit input[type='text']:focus {
    background: #000;
}

.radio-container label,
.radio-container input {
    display: inline-block;
    margin: 1px 2px;
    vertical-align: middle;
}

.radio-container input {
    margin: 0 16px 0 0;
}

/*****ADMIN*****/
.admin-table:not(.admin-topic) .row:first-child {
    font-weight: bold;
}

.admin-table .col-sm-2 {
    margin: 0 0 50px;
}

.admin-table .button {
    margin: 0 0 10px;
}

/*****PAGINATION*****/
.next-topics {
    position: relative;
    z-index: 1;
    float: right;
}

.home .next-topics {
    float: none;
}

.pagination {
    z-index: 10;
    padding: 20px;
    background: #000;
}

/*****TOPIC PAGE*****/
.topic-image {
    width: 200px;
    margin: 10px auto;
}

.topic-image img {
    display: block;
    width: 200px;
    height: 200px;
    border: 4px solid $green;
    border-radius: 50%;
}

.single-topic-page .main {
    padding: 70px 0;
}

.main-black-img-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 800px;
    opacity: .25;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.single-topic-page .main h1 {
    margin: 0 0 5px;
}

.single-topic-page .topic-heading,
.single-news .topic-heading {
    margin: 0 0 70px;
}

.single-topic-page .blurb,
.single-news .blurb {
    width: 80%;
    margin: 0 auto 40px;
}

.single-topic-page .blurb p,
.single-news .blurb p {
    font: 19px/2.1 $font-stack-1;
    margin: 0 0 25px;
}

.single-news .blurb iframe {
    display: block;
    margin: 30px auto !important;
}

.topic-sort-by {
    float: right;
    width: 200px;
}

.single-topic-page .sort-by-block {
    float: none;
}

.single-topic-page .sort-by-block label {
    font: 900 15px/34px $font-stack-2;
    margin: 0 10px 0 0;
    vertical-align: top;
    text-transform: uppercase;
}

.single-topic-page .sort-by-block select {
    display: inline-block;
    max-width: 180px;
    margin: 0 68px 0 0;
    border: 2px solid $pink;
}

.single-topic-page .sort-by-block select:hover {
    background: $green;
}

.single-topic-page .sort-by-block select:focus {
    color: #fff;
    background: $pink;
}

.topic-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
}

.topic-nav li {
    display: inline-block;
    margin: 0 15px 30px;
}

.topic-nav li a {
    margin: 0;
    color: #fff;
}

.topic-nav.best li:first-child a,
.topic-nav.day li:nth-child(2) a,
.topic-nav.week li:nth-child(3) a,
.topic-nav.month li:nth-child(4) a,
.topic-nav.new li:nth-child(5) a {
    color: #000;
    background: $blue;
}

.topic-nav li a:hover {
    color: #000;
    background: $green;
}

.single-topic-page hr {
    margin: 20px auto 35px;
}

.bottom_content_heading h2 {
    font: 200 60px/1.2em $font-stack-2;
    margin: 75px 0 0;
}

.col-sm-4.home-column.comment-block {
    margin: 0 0 75px;
    padding: 0 40px;
}

.topic-page .main.black,
.profile-page .main.black {
    min-height: 380px;
    padding: 0 0 40px;
}

.profile-page .main.black {
    min-height: 225px;
}

.topic-page .main.black h1,
.profile-page .main.black h1 {
    margin: 60px 0 30px;
}

.profile-page .main.black h1 {
    margin: 60px 0 10px;
}

.profile-top-section {
    margin-bottom: 30px;
}

.profile-image {
    display: block;
    overflow: hidden;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    padding: 15px;
    border: 4px solid $green;
    border-radius: 50%;
}

.profile-image > img {
    border-radius: 50%;
}

.comment-block .profile-image-container {
    position: absolute;
    z-index: 2;
    bottom: -24px;
    left: -25px;
}

.comment-block .profile-image {
    display: block;
    width: 50px;
    height: 50px;
    padding: 0;
    border: 2px solid $green;
    background: #fff;
}

.comment-block .profile-image.default-image {
    background: #000;
}

a.profile-image:hover {
    border-color: $pink;
}

.profile-image.no-padding {
    padding: 0;
}

.profile-image:after {
    font: 190px/220px 'icomoon';
    content: '\e971';
}

.comment-block .profile-image.default-image:after {
    font: 46px/52px 'icomoon';
    color: #fff;
}

.profile-image-link {
    font: italic 12px/1.4 $font-stack-1;
    color: #fff;
}

.profile-image-page .button.large {
    margin: 10px 10px 30px;
}

/*** PROFILE PAGE~IMAGE SELECTION ***/
.profile-page.profile-image-page .main.black h2 {
    clear: both;
    margin: 60px 0 30px;
}

.profimg-block {
    margin-bottom: 30px;
}

.profile-image-page .profile-image input {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}

.profile-image-page .profimg-block .profile-image:hover,
.profile-image-page .profimg-block .profile-image.active {
    border-color: $pink;
}

/*****FOOTER*****/

.footer {
    padding: 45px 0;
    text-align: center;
    background: #fcfcfc;
    box-shadow: 0 -1px 0 0 #f3f3f3;
}

.footer p {
    font: 15px/1.8 $font-stack-1;
    margin: 0;
}

.footer p:first-child {
    font: italic 15px/1.8 $font-stack-1;
    margin: 0 0 12px;
    color: #a8a8a8;
}

.footer p:last-child a {
    color: #000;
    border-bottom: 2px solid $blue;
}

.footer p:last-child a:hover {
    color: #9d9d9d;
    border-color: $green;
}

/*****PROFILE PAGE*****/
.change-username {
    margin: 0 0 15px;
}

.change-username-form {
    display: none;
    margin: 30px 0 0;
}

.profile-link {
    margin: 0 10px 30px 0;
}

.profile-page .main {
    padding: 55px 0 70px;
}

.profile-page h3.topic-heading {
    display: inline-block;
    margin: 0 0 50px;
    padding: 10px 30px;
}

.profile-page h3.topic-heading.changed-user-heading {
    color: #000;
}

.profile-page h3.topic-heading.changed-user-heading:after {
    display: none;
}

.profile-page .form-block h4,
.single-comment-page .form-block h4:not(.link),
.single-comment-page .form-block h4.link a {
    font: bold 14px/52px $font-stack-1;
    display: inline-block;
    margin: 0 5px 15px 0;
    padding: 0 22px;
    color: #000;
    border-radius: 5px;
    background: $green;
}

.single-comment-page .form-block h4:not(.link),
.single-comment-page .form-block h4.link a {
    font: bold 14px/2.2 $font-stack-1;
    padding: 12px 22px;
}


.single-comment-page .form-block h4.link {
    display: inline-block;
    margin: 0;
}

.single-comment-page .form-block h4.link a {
    display: block;
    color: #fff;
    background: $pink;
}

.single-comment-page .form-block h4.link a:hover {
    color: #000;
    background: $blue;
}

.profile-page .form-block p {
    color: #fff;
}

.profile-page hr {
    clear: both;
    width: 100%;
    margin: 40px 0;
}

.profile-page .pending-block {
    margin: 0 0 60px;
}

.main.black h2.message-heading.facebook-heading {
    margin: 50px 0 30px;
}

.pending-block p {
    font-weight: bold;
}

.profile-pending h2 {
    margin: 0 0 50px;
}

.profile-page .profile-pending h3.topic-heading {
    margin: 0 0 30px;
}

.profile-buttons {
    margin: 50px 0 30px;
}

.profile-buttons .button {
    margin: 0 10px 0 0;
}

/***CHANGE USERNAME PAGE***/
.change-user-bottom a {
    margin: 0 20px 20px;
}

/*****COMMENT SINGLE*****/
.single-comment-block {
    font: 22px/1.8 $font-stack-1;
    margin: 0 0 30px;
    padding: 20px;
    border-radius: 5px;
    background: $green;
}

.single-comment-block p:last-child {
    margin: 0;
}

.single-comments-topics a {
    margin: 0 10px 10px 0;
}

.single-comments-topics a:hover,
.single-users-liked li a:hover {
    color: #000;
    background: $blue;
}

.single-users-liked {
    padding: 0;
    list-style: none;
}

.single-users-liked li {
    display: inline-block;
    margin: 0 10px 10px 0;
}

/*****NEWS INDEX*****/

.news-block {
    margin-bottom: 70px;
}

.news-block .topic-heading {
    margin: 0 0 40px;
}

/*****NEWS SINGLE*****/
.single-news .main {
    padding: 70px 0;
}

.single-news .main h1 {
    font: 900 36px/1.6em $font-stack-2;
    margin: 20px 0;
}

.single-news select {
    border: 2px solid $pink;
}

.single-news .main hr {
    margin: 10px auto 70px;
}

.main.news-submit {
    overflow: hidden;
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
    height: 76px;
    min-height: 0;
    padding: 15px 0 30px;
    -webkit-transition: 300ms linear;
         -o-transition: 300ms linear;
            transition: 300ms linear;
    border-bottom: 1px solid $green;
    background: #fcfcfc;
    box-shadow: 0 1px 0 0 #f3f3f3;
}

.main.news-submit.active {
    height: auto;
}

.main.news-submit h2 {
    font: 700 36px/1 $font-stack-2;
    margin: 20px 0 30px;
}

.main.news-submit h2 a {
    display: inline-block;
    color: #000;
}

.main.news-submit h2 a:hover {
    color: $green;
}

.main.news-submit h2 span {
    margin: 0 0 0 20px;
}

.main.news-submit h2 span:before {
    font: 200 24px/50px 'icomoon';
    font-weight: bold;
    display: inline-block;
    width: 54px;
    height: 54px;
    content: '\ea0a';
    cursor: pointer;
    -webkit-transition: 300ms linear;
         -o-transition: 300ms linear;
            transition: 300ms linear;
    -webkit-transform-origin: center;
       -moz-transform-origin: center;
        -ms-transform-origin: center;
         -o-transform-origin: center;
            transform-origin: center;
    text-align: center;
    vertical-align: middle;
    color: $blue;
    border: 2px solid #000;
    border-radius: 50%;
    background: #000;
}

.main.news-submit h2 a:hover span:before {
    color: $green;
    border-color: $green;
}

.main.news-submit.active h2 span:before {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}

.main.news-submit .topic-heading {
    margin: 5px 0 30px;
}

.main.news-submit label {
    color: #000;
}

.topics-container {
    padding: 0;
}

.topics-container li {
    display: inline-block;
    margin: 0 0 10px 10px;
}

.main .news-bottom h2 {
    font: 200 70px/1.5em $font-stack-2;
    padding: 30px 0 0;
}

.single-news-links p > a {
    display: block;
}

.real-time-toggle {
    float: left;
}

.real-time-toggle .fieldset {
    display: inline-block;
    padding: 2px;
    border: 2px solid $pink;
    border-radius: 50em;
}

.real-time-toggle input[type='radio'] {
    position: absolute;
    opacity: 0;
}

.real-time-toggle label {
    font: bold 14px/40px $font-stack-1;
    z-index: 1;
    display: inline-block;
    float: left;
    width: 70px;
    height: 40px;
    margin: 0;
    cursor: pointer;
    color: #000;
}

.real-time-toggle .on label:nth-child(2),
.real-time-toggle .off label:nth-child(4) {
    color: $green;
}

.real-time-toggle .cd-switch {
    /* floating background */
    position: absolute;
    top: 2px;
    left: 2px;
    width: 70px;
    height: 40px;
    -webkit-transition: -webkit-transform .5s;
       -moz-transition:    -moz-transform .5s;
            transition:         transform .5s;
    border-radius: 50em;
    background-color: #000;
}

.real-time-toggle input[type='radio']:checked + label + .cd-switch {
    -webkit-transform: translateX(70px);
       -moz-transform: translateX(70px);
        -ms-transform: translateX(70px);
         -o-transform: translateX(70px);
            transform: translateX(70px);
}

.sort-by-block {
    float: right;
}

.ng-move,
.ng-enter,
.ng-leave {
    -webkit-animation-name: zoomInUp;
            animation-name: zoomInUp;
    /*-webkit-transition:all linear 5s;
	transition:all linear 5s;*/
    -webkit-animation-duration: 1s;
            animation-duration: 1s;

    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}

.ng-leave.ng-leave-active,
.ng-move,
.ng-enter {
    /*opacity:0;*/
}

.ng-leave,
.ng-move.ng-move-active,
.ng-enter.ng-enter-active {
    /*opacity:1;*/
}

.no-comments-found {
    font: 900 24px/1.5em $font-stack-2;
}

.single-news .comment-text {
    padding: 50px 30px 30px;
    border-top: 2px solid $blue;
    border-radius: 5px 5px 0 5px;
}

.single-news .votes button span:last-child {
    font-size: 13px;
}

.single-news .nested .comment-text {
    border-color: $green;
}

.single-news .nested .comment-text:after,
.single-news .nested .comment-text:before {
    position: absolute;
    top: -30px;
    right: 30px;
    width: 0;
    height: 0;
    content: '';
    border-width: 0 20px 30px 20px;
    border-style: solid;
    border-color: transparent transparent $green transparent;
}

.single-news .nested .comment-text:before {
    z-index: 2;
    top: -26px;
    right: 31px;
    border-width: 0 19px 28px 19px;
    border-color: transparent transparent #fff transparent;
}

.single-news h5.topics-heading a {
    font: 14px/34px $font-stack-1;
    padding: 0 16px;
}

.replies-button-container {
    margin: -45px 0 0;
}

/* MEDIA QUERIES */
@media (max-width: 1200px) {
    /* HEADER */
    .header ul.navigation {
        float: none;
        margin: 0;
    }

    .header .logo {
        float: none;
    }

    .header ul.navigation > li:first-child > a {
        padding: 0 18px 0 0;
    }

    /* HOME */
    .home-half-width {
        padding-right: 60px;
        padding-left: 60px;
    }
}

@media (max-width: 991px) {
    /* HEADER */
    .header {
        text-align: center;
    }

    .header .logo,
    .loggedin .header .logo {
        display: inline-block;
        margin: 10px 0 0;
    }

    .header ul.navigation > li > a {
        font: 15px/54px $font-stack-1;
    }

    .login,
    .loggedin .login {
        margin: 0;
        text-align: center;
    }

    .header .login > h2 {
        display: none;
    }

    /* HOME */
    .main.black {
        padding: 45px 0;
    }

    .home-heading ul li {
        display: none;
    }

    .home-heading ul li.show:first-child {
        position: relative;
        display: block;
    }

    .home .main ul {
        width: auto !important;
        height: auto;
    }

    .home .main.black h2 {
        font: 200 44px/1.2em $font-stack-2;
        margin: 10px 0 24px;
    }

    .home .main.black h3 {
        margin: 0 auto 15px;
    }

    .speech-bubble.context,
    .speech-bubble.response {
        max-width: none;
    }

    .home .main.black.trending-stories h3 {
        font: 900 24px/1.5em $font-stack-2;
        margin: 0 0 30px;
    }

    .trending-story-block {
        margin-bottom: 40px;
    }

    .home-half-width:first-child {
        box-shadow: 0 1px 0 0 #f3f3f3;
    }

    .home-half-width:last-child h2 {
        margin: 36px 0 10px;
    }

    .inner-bottom-section:before {
        display: none;
    }

    /* SINGLE NEWS */
    .single-news-links {
        text-align: center;
    }
}

@media (max-width: 767px) {
    /* HEADER */
    .header,
    .loggedin .header {
        padding: 24px 0 0;
    }

    .header .logo {
        font: 900 42px/1.3em $font-stack-2;
        margin: 2px 0 0;
        vertical-align: top;
    }

    .header .logo:before {
        top: -4px;
    }

    .burger {
        display: inline-block;
    }

    .header ul.mobile-nav {
        display: block;
    }

    .header ul.navigation {
        display: none;
    }

    .login,
    .loggedin .login {
        margin: 0 0 5px;
    }

    .header a.button {
        min-width: 100px;
        vertical-align: top;
    }

    .login > .button {
        margin: 0 5px 10px;
    }

    .header .login-popup {
        top: 64px;
        right: 50%;
        margin: 0 -132px 0 0;
    }

    .login-popup .tooltip-content.left {
        position: relative;
        left: auto;
        margin: -10px 0 6px;
    }

    .login-popup .tooltip-content.left:after {
        display: none;
    }

    /* HOME */
    .top-menu li a {
        margin: 0 20px 20px;
    }

    .comment-block:last-child {
        margin: 20px 0 35px;
    }

    .comment-text {
        padding: 20px;
    }

    .comment-text-top,
    .main h3.comment-text-top {
        padding: 30px 10px 14px;
        text-align: center;
    }

    .home .main.best-comments-section {
        padding: 0 40px 40px 50px;
    }

    .sign-up-section-bottom .white-button {
        margin: 0 0 20px;
    }

    h4.comment-meta,
    h5.topics-heading,
    .home-half-width .comment-meta,
    .home-half-width h5.topics-heading {
        float: none;
        max-width: none;
    }

    h5.topics-heading {
        text-align: left;
    }

    /* INTERIOR */
    .main h1,
    .bottom_content_heading h2,
    .main .news-bottom h2 {
        font: 200 44px/1.2em $font-stack-2;
        margin: 60px 0 30px;
    }

    .bottom_content_heading h2 {
        margin: 60px 0 0;
    }

    .topic-heading {
        font: 900 18px/1.5em $font-stack-2;
    }

    .form-block.button-form-block {
        text-align: center;
    }

    /* SINGLE TOPICS PAGE */
    .topic-nav li {
        margin: 0 10px 20px;
    }

    .topic-nav li a {
        font: bold 12px/52px $font-stack-1;
    }

    .single-topic-page .main {
        padding: 0 10px 40px 15px;
    }

    .single-topic-page .main .button.large,
    .profile-page .main.black .button.large {
        font: bold 16px/50px $font-stack-1;
        height: 50px;
    }

    .single-topic-page .sort-by-block label {
        display: block;
        margin: 0;
    }

    .single-topic-page .sort-by-block select {
        margin: 0 0 20px;
    }

    /* PROFILE PAGE */
    .profile-page .main.black {
        text-align: center;
    }

    .profile-page .main.black .form-block {
        text-align: left;
    }

    .profile-page .main.black .form-block.button-form-block {
        text-align: center;
    }

    .profile-page h3.topic-heading {
        padding: 10px 26px;
    }

    .profile-page .main.black .button {
        margin: 0 5px 10px;
    }

    /* TOPICS AND NEWS INDEX PAGES */
    .topics-index .main h1,
    .news-story-index .main h1 {
        margin: 30px 0 20px;
    }

    .topics-index .main hr,
    .news-story-index .main hr {
        margin: 0 auto 40px;
    }

    /* SINGLE NEWS */
    .main.news-submit {
        height: 75px;
    }

    .main.news-submit h2 {
        font: 700 30px/1 $font-stack-2;
        margin: 10px 0;
    }

    .main.news-submit h2 a:hover,
    .main.news-submit h2 a:focus {
        color: #000;
    }

    .main.news-submit h2 span {
        display: block;
        margin: 10px 0 0;
    }

    .main.news-submit h2 span:before {
        font: 200 18px/36px 'icomoon';
        width: 40px;
        height: 40px;
    }

    .main .news-bottom h2 {
        margin: 0;
    }

    .single-news .comment-block {
        margin: 20px 0 125px 10px;
    }

    .single-news .comment-block.nested {
        margin: 55px 4% 55px 20%;
    }

    /* SINGLE COMMENT PAGE */
    .single-comment-page .main h1  {
        margin: 30px 0;
    }
}

@media (max-width: 479px) {
    /* GLOBAL */
    hr {
        width: 26%;
    }

    /* HEADER */
    .header,
    .loggedin .header {
        padding: 16px 0 0;
    }

    .header a.button {
        font: bold 13px/52px $font-stack-1;
        padding: 0 16px;
    }

    .header a.button.submit-comment span {
        display: none;
    }

    /* HOME */
    .top-menu {
        margin: 40px 0 10px;
    }

    .top-menu li a {
        font: 16px/1.8 $font-stack-1;
        width: 80px;
        margin: 0 10px 14px;
    }

    .top-menu li a:before {
        display: block;
        width: 0;
        height: 0;
        margin: 0 0 8px;
        content: '';
        border-width: 0 40px 70px 40px;
        border-style: solid;
        border-color: transparent transparent $pink transparent;
    }

    .top-menu li a img {
        top: 34px;
        width: 24px;
        margin: 0 0 0 -12px;
    }

    .main.black {
        padding: 30px 0;
    }

    .home .main.black {
        margin: 0;
    }

    .home .main h2,
    .home-half-width h2 {
        font: 200 38px/1.3em $font-stack-2;
        margin: 30px 0 10px;
    }

    .top-menu li a {
        margin: 0 16px 20px;
    }

    h3.home-sub-heading {
        font: 900 18px/1.5em $font-stack-2;
    }

    .speech-bubble .speech-bubble-inner {
        font: 15px/1.8 $font-stack-1;
        padding: 15px 20px;
    }

    .speech-bubble h5 {
        font: 900 12px/1.5em $font-stack-2;
    }

    .home-half-width {
        padding-right: 28px;
        padding-left: 38px;
    }

    .comment-text-top {
        font: 700 14px/1.5em $font-stack-2;
    }

    .comment-text {
        font: 16px/1.8 $font-stack-1;
    }

    .comment-text-top,
    .main h3.comment-text-top {
        padding: 14px;
    }

    .votes .tooltip-content {
        right: -200px;
        bottom: -6px;
        left: auto;
        margin: 0;
        -webkit-transition: opacity .3s, -webkit-transform .3s;
                transition: opacity .3s, transform .3s;
        -webkit-transform: translate3d(10px, 0,0);
                transform: translate3d(10px, 0,0);
    }

    .votes .tooltip-content.active {
        right: -234px;
    }

    .votes .tooltip-content:after {
        bottom: 50%;
        left: -16px;
        margin: 0 0 -8px 0;
        -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
             -o-transform: rotate(90deg);
                transform: rotate(90deg);
    }

    .votes .active.tooltip-content {
        -webkit-animation: tooltipHoverMobile 350ms linear;
                animation: tooltipHoverMobile 350ms linear;
    }

    @-webkit-keyframes tooltipHoverMobile {
        0% {
            -webkit-transform: translate3d(20px,0,0);
            opacity: 0;
        }
        100% {
            -webkit-transform: translate3d(0,0,0);
            opacity: 1;
        }
    }

    @keyframes tooltipHoverMobile {
        0% {
            transform: translate3d(20px,0,0);
            opacity: 0;
        }
        100% {
            transform: translate3d(0,0,0);
            opacity: 1;
        }
    }

    .votes {
        top: -20px;
        left: -20px;
    }

    .votes button {
        font-size: 14px;
        line-height: 40px;
        height: 44px;
    }

    .votes button span:first-child {
        font-size: 18px;
        line-height: 38px;
        width: 40px;
        height: 40px;
    }

    .votes button span:last-child,
    .single-news .votes button span:last-child {
        font-size: 0;
        display: block;
        margin: 0;
        padding: 0;
    }

    .votes button span:last-child:after {
        position: absolute;
        top: -76px;
        left: 10px;
        margin: 0;
    }

    h4.comment-meta {
        font: italic 11px/1.5 $font-stack-1;
        margin: 6px 0 0;
    }

    h4.comment-meta span {
        display: none;
    }

    h5.topics-heading {
        padding: 0 14px;
    }

    .white-button.large {
        font: 400 18px/50px $font-stack-2;
        height: 54px;
        padding: 0 20px;
    }

    .home .main.black.trending-stories h3 {
        font: 900 16px/1.5em $font-stack-2;
        margin: 0 0 30px;
    }

    .trending-stories ul li a,
    .news-story-index .main ul li a {
        font: bold 14px/1.5 $font-stack-1;
        height: auto;
        margin: 16px 0 0;
        padding: 0;
        color: $blue;
        background: none;
    }

    .trending-stories ul li a:hover,
    .news-story-index .main ul li a:hover {
        color: $pink;
    }

    .home .main.best-comments-section {
        padding: 0 28px 20px 38px;
    }

    .trending-stories ul li a,
    .popular-topics-section ul li a,
    .news-story-index .main ul li a,
    .topics-index .main ul li a {
        padding: 0 30px;
    }

    .home .main.popular-topics-section {
        margin: 0 0 10px;
        padding: 50px 0;
    }

    .home .main.popular-topics-section h2 {
        margin: 0 0 10px;
    }

    .main.sign-up-section-bottom {
        margin: 0 0 10px;
        padding: 0 0 20px;
    }

    .home .main.sign-up-section-bottom h3 {
        font: 900 28px/1.3em $font-stack-2;
    }

    .main.black.trending-stories {
        padding: 60px 0;
    }

    .trending-stories h4 {
        margin: 0 0 14px;
    }

    .trending-story-block {
        margin-bottom: 50px;
    }

    .trending-stories .white-button {
        margin: 0;
    }

    /* INTERIOR */
    .main,
    .main.black {
        min-height: 0;
    }

    .bottom_content_heading h2 {
        font: 200 38px/1.2em $font-stack-2;
        margin: 50px 15px 0;
    }

    .main .form-block {
        padding: 0 10px;
    }

    /* SINGLE TOPICS PAGE */
    .single-topic-page .main {
        padding: 0 0 20px 8px;
    }

    .topic-nav li a {
        font: bold 12px/49px $font-stack-1;
        height: 49px;
        padding: 0 12px;
    }

    .single-topic-page .blurb,
    .single-news .blurb {
        width: auto;
    }

    /* SINGLE COMMENT PAGE */
    .single-comment-page .main .form-block {
        padding: 0 25px;
    }

    /* SINGLE NEWS PAGE */
    .main.news-submit {
        height: 70px;
    }

    .single-news .main h1 {
        font: 200 34px/1.4em $font-stack-2;
        margin: 30px 0 20px;
    }

    .main.news-submit h2 {
        font: 700 24px/1.1 $font-stack-2;
    }

    .single-news .comment-text {
        padding: 28px;
    }

    .real-time-toggle {
        float: none;
    }

    .sort-by-block {
        float: none;
        width: 60%;
        margin: 0 auto;
    }

    .single-news .main.news-submit + .main {
        padding: 70px 0 0;
    }

    .single-news .comment-block.nested {
        margin: 55px 4% 55px 12%;
    }
}